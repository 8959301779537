 
<div class="w3-light-grey">

<!-- Page Container -->
<div class="w3-content w3-margin-top" style="max-width:1400px;">

  <!-- The Grid -->
  <div class="w3-row-padding">
  
    <!-- Left Column -->
    <div class="w3-third">
    
      <div class="w3-white w3-text-grey w3-card-4">
        <div class="w3-display-container">
          <img src="assets/profile.png" style="width:100%" alt="Avatar">
          <div class="w3-display-bottomleft name-class-custom w3-container w3-text-black typewriter">
            <h2> {{ '{ $Ram Krishna }' }} </h2>
          </div>
        </div>
        <div class="w3-container">
          <p><i class="fa fa-briefcase fa-fw w3-margin-right w3-large w3-text-teal"></i>Full Stack Developer | DevOps</p>
          <p><i class="fa fa-home fa-fw w3-margin-right w3-large w3-text-teal"></i>Patna, India</p>
          <p><i class="fa fa-envelope fa-fw w3-margin-right w3-large w3-text-teal"></i>ramkrishna70@live.com</p> 
          <p><i class="fa fa-whatsapp fa-fw w3-margin-right w3-large w3-text-teal"></i>+91- 9113366525</p>
          <p><i class="fa fa-skype fa-fw w3-margin-right w3-large w3-text-teal"></i>ramkrishnadj</p>
          <hr>

          <p class="w3-large"><b><i class="fa  fa-hand-o-right fa-fw w3-margin-right w3-text-teal"></i>Programming Skills</b></p> 
          <p>JavaScript</p>
          <div class="w3-light-grey w3-round-xlarge w3-small">
            <div class="w3-container w3-center w3-round-xlarge w3-teal custome-font" style="width:80%">80%</div>
          </div>
          <p>PHP</p>
          <div class="w3-light-grey w3-round-xlarge w3-small">
            <div class="w3-container w3-center w3-round-xlarge w3-teal custome-font" style="width:95%">
              <div class="w3-center w3-text-white">95%</div>
            </div>
          </div>
          <p>Python</p>
          <div class="w3-light-grey w3-round-xlarge w3-small">
            <div class="w3-container w3-center w3-round-xlarge w3-teal custome-font" style="width:40%">40%</div>
          </div>
          <p>Angular</p>
          <div class="w3-light-grey w3-round-xlarge w3-small">
            <div class="w3-container w3-center w3-round-xlarge w3-teal custome-font" style="width:85%">85%</div>
          </div>
          <p>Java</p>
          <div class="w3-light-grey w3-round-xlarge w3-small">
            <div class="w3-container w3-center w3-round-xlarge w3-teal custome-font" style="width:75%">75%</div>
          </div>
          <p>NodeJs</p>
          <div class="w3-light-grey w3-round-xlarge w3-small">
            <div class="w3-container w3-center w3-round-xlarge w3-teal custome-font" style="width:85%">85%</div>
          </div>
          <p>C</p>
          <div class="w3-light-grey w3-round-xlarge w3-small">
            <div class="w3-container w3-center w3-round-xlarge w3-teal custome-font" style="width:85%">85%</div>
          </div>
          <p>TypeScript</p>
          <div class="w3-light-grey w3-round-xlarge w3-small">
            <div class="w3-container w3-center w3-round-xlarge w3-teal custome-font" style="width:75%">75%</div>
          </div>
          <p>Shell Scripting</p>
          <div class="w3-light-grey w3-round-xlarge w3-small">
            <div class="w3-container w3-center w3-round-xlarge w3-teal custome-font" style="width:95%">95%</div>
          </div>
          <br>


          <p class="w3-large"><b><i class="fa  fa-hand-o-right fa-fw w3-margin-right w3-text-teal"></i>IT skills</b></p> 
          <p>Server Administration</p>
          <div class="w3-light-grey w3-round-xlarge w3-small">
            <div class="w3-container w3-center w3-round-xlarge w3-teal custome-font" style="width:75%">75%</div>
          </div>
          <p>Network Administration</p>
          <div class="w3-light-grey w3-round-xlarge w3-small">
            <div class="w3-container w3-center w3-round-xlarge w3-teal custome-font" style="width:85%">85%</div>
          </div>
          <p>System Engineering</p>
          <div class="w3-light-grey w3-round-xlarge w3-small">
            <div class="w3-container w3-center w3-round-xlarge w3-teal custome-font" style="width:70%">70%</div>
          </div>
          <p>Deployments with Automation</p>
          <div class="w3-light-grey w3-round-xlarge w3-small">
            <div class="w3-container w3-center w3-round-xlarge w3-teal custome-font" style="width:90%">90%</div>
          </div>
          <p>GIT | SVN | HOSTING </p>
          <div class="w3-light-grey w3-round-xlarge w3-small">
            <div class="w3-container w3-center w3-round-xlarge w3-teal custome-font" style="width:95%">95%</div>
          </div>
          <p>Cordova | Firebase | Cloud Functions </p>
          <div class="w3-light-grey w3-round-xlarge w3-small">
            <div class="w3-container w3-center w3-round-xlarge w3-teal custome-font" style="width:85%">85%</div>
          </div>
          <p>DNS | Network Security | Hacks </p>
          <div class="w3-light-grey w3-round-xlarge w3-small">
            <div class="w3-container w3-center w3-round-xlarge w3-teal custome-font" style="width:90%">90%</div>
          </div>

          <br>
          <p class="w3-large w3-text-theme"><b><i class="fa fa-hand-o-right fa-fw w3-margin-right w3-text-teal"></i>Soft Skills</b></p>

          <p>Team Lead | Team Management</p>
          <div class="w3-light-grey w3-round-xlarge w3-small">
            <div class="w3-container w3-center w3-round-xlarge w3-teal custome-font" style="width:80%">80%</div>
          </div>
          <p>Communication | Project Management</p>
          <div class="w3-light-grey w3-round-xlarge w3-small">
            <div class="w3-container w3-center w3-round-xlarge w3-teal custome-font" style="width:85%">85%</div>
          </div>
          <p>Analysis | Web design</p>
          <div class="w3-light-grey w3-round-xlarge w3-small">
            <div class="w3-container w3-center w3-round-xlarge w3-teal custome-font" style="width:85%">85%</div>
          </div>
          <p>Educating Team  | Risk Analysis</p>
          <div class="w3-light-grey w3-round-xlarge w3-small">
            <div class="w3-container w3-center w3-round-xlarge w3-teal custome-font" style="width:90%">90%</div>
          </div>

          <br>
          <p class="w3-large w3-text-theme"><b><i class="fa fa-hand-o-right fa-fw w3-margin-right w3-text-teal"></i>Extra Skills</b></p>

          <p>Computer | Laptop Assemble</p>
          <div class="w3-light-grey w3-round-xlarge w3-small">
            <div class="w3-container w3-center w3-round-xlarge w3-teal custome-font" style="width:90%">90%</div>
          </div>
          <p>PACS | DICOM</p>
          <div class="w3-light-grey w3-round-xlarge w3-small">
            <div class="w3-container w3-center w3-round-xlarge w3-teal custome-font" style="width:65%">65%</div>
          </div>
          <p>DICOM Image Viewers</p>
          <div class="w3-light-grey w3-round-xlarge w3-small">
            <div class="w3-container w3-center w3-round-xlarge w3-teal custome-font" style="width:65%">65%</div>
          </div>
          <p>Remote Support</p>
          <div class="w3-light-grey w3-round-xlarge w3-small">
            <div class="w3-container w3-center w3-round-xlarge w3-teal custome-font" style="width:95%">95%</div>
          </div>
          <br>

          <p class="w3-large w3-text-theme"><b><i class="fa fa-hand-o-right fa-fw w3-margin-right w3-text-teal"></i>Languages</b></p>
          <p>Hindi</p>
          <div class="w3-light-grey w3-round-xlarge">
            <div class="w3-round-xlarge w3-teal custome-font" style="height:15px;width:100%"></div>
          </div>
          <p>English</p>
          <div class="w3-light-grey w3-round-xlarge">
            <div class="w3-round-xlarge w3-teal custome-font" style="height:15px;width:85%"></div>
          </div>
          <br>
          <div style="padding: 27px 0px;">
          	{{date_time}}
          </div>
        </div>
      </div><br>

    <!-- End Left Column -->
    </div>

    <!-- Right Column -->
    <div class="w3-twothird">
    
      <div class="w3-container w3-card w3-white w3-margin-bottom">
        <h2 class="w3-text-grey w3-padding-16"><i class="fa fa-suitcase fa-fw w3-margin-right w3-xxlarge w3-text-teal"></i>Work Experience</h2>
        <div class="w3-container">
          <h5 class="w3-opacity"><b>Lead Consultant / <a href="https://invincix.com" style="text-decoration: none;">Invincix Solutions Private Ltd.</a></b></h5>
          <h6 class="w3-text-teal"><i class="fa fa-calendar fa-fw w3-margin-right"></i>Apr 2020 - <span class="w3-tag w3-teal w3-round">Current</span></h6>
          <p>Gathering high level information of requirement and pass to the team. Making communication with the client and working close with the team to achieve the goal. Finding the best fit resource in team. My work expands when I look into the Server administration, Network Administration and in Cloud. Ensuring the security of the platforms, where my team works and collaborate.
          It also includes reviewing the quality of work, and TDD tests.</p>
          <hr>
        </div>
        <div class="w3-container">
          <h5 class="w3-opacity"><b>Consultant / <a href="https://invincix.com" style="text-decoration: none;">Invincix Solutions Private Ltd.</a></b></h5>
          <h6 class="w3-text-teal"><i class="fa fa-calendar fa-fw w3-margin-right"></i>Apr 2019 - Mar 2020</h6>
          <p>I was working on the mobile application development and also for web application development. This whole 1 year was challenging for me, encountering different types of obstacles and blockage. I felt some hard time during this, and it passed. I just kept doing my work with dedication. 
          During this time I learned about AWS, Mobile security, and network security. Also improved the client communication.</p>
          <hr>
        </div>
        <div class="w3-container">
          <h5 class="w3-opacity"><b>Associate consultant / <a href="https://invincix.com" style="text-decoration: none;">Invincix Solutons Private Ltd.</a></b></h5>
          <h6 class="w3-text-teal"><i class="fa fa-calendar fa-fw w3-margin-right"></i>Aug 2017 - Mar 2019</h6>
          <p>I was working in a team, and I was writing the logics for the mobile application. It was time, when i was doing code, testing it, and pushing to staging. This time given me opportunity to learn more and consume more, and also to enhance the skill.</p>
        </div>
        <hr>
        <div class="w3-container">
          <h5 class="w3-opacity"><b>Software Engineer / <a href="http://www.twinpod.com" style="text-decoration: none;">Twinpod Inc</a></b></h5>
          <h6 class="w3-text-teal"><i class="fa fa-calendar fa-fw w3-margin-right"></i>Dec 2016 - Jul 2017</h6>
          <p>Wirking on application support, based on PACS. Also looking in generated issue tickets by the client, and fixing those issues in given SLA. Looking in the hosting of the applications.</p> 
        </div>

        <hr>
        <div class="w3-container">
          <h5 class="w3-opacity"><b>Blogger / <a href="https://bithost.in" style="text-decoration: none;">BitHostIn</a></b></h5>
          <h6 class="w3-text-teal"><i class="fa fa-calendar fa-fw w3-margin-right"></i>Apr 2020 - <span class="w3-tag w3-teal w3-round">Current</span></h6>
          <p>Write my findings and contents here. Most of contents are related to the new learnings about the programming language, and it's usage and deployment. </p>
          <hr>
        </div>

      </div>


      <div class="w3-container w3-card w3-white">
        <h2 class="w3-text-grey w3-padding-16"><i class="fa  fa-university fa-fw w3-margin-right w3-xxlarge w3-text-teal"></i>Education</h2>
        <div class="w3-container">
          <h5 class="w3-opacity"><b>Master of Computer Applications</b></h5>
          <h6 class="w3-text-teal"><i class="fa fa-calendar fa-fw w3-margin-right"></i>Forever</h6>
          <p>Completed my MCA in 2017 from <b>Reva Institute of Technology And Management</b>, affiliated with  <b>Visvesvaraya Technological University</b>, Bangalore.<br>
          	<b>Field Of study:</b> Networking, DataStructure, C++, Java, C, Python, Soft Skill, Computer Application, Client / Server Application.<br>
          	<b>Grade :</b> First Class  (67.8 %)</p>
          <hr>
        </div>
        <div class="w3-container">
          <h5 class="w3-opacity"><b>Bachelor of Computer Application</b></h5>
          <h6 class="w3-text-teal"><i class="fa fa-calendar fa-fw w3-margin-right"></i>Forever</h6>
          <p>Completed my graduation in 2014 from <b>Bihar National College</b> affiliated with <b>Patna University, Patna</b><br>
          	<b>Field Of Study:</b> Unix OS, Windows 95, Computer Fundamentals, Shell Scripting, ERP, Economics, Mathematical statistics <br> 
          	<b>Grade :</b> First Class (73.5 %)</p>
          <hr>
        </div>
      </div>

      <div class="w3-container w3-card w3-white">
      	<h2 class="w3-text-grey w3-padding-16"><i class="fa fa-certificate fa-fw w3-margin-right w3-xxlarge w3-text-teal"></i>Certificates</h2>
      	<div class="row">
      		<div class="col-md-6">
      			<img src="assets/certificates/linuxplus.png" class="certificate-class" (click)="open_url('https://api-courses.cybrary.it/api/certificate/C-5a3cf011f-f52ea8/view')" alt="cert">
      		</div>
      		<div class="col-md-6">
      			<img src="assets/certificates/advance-p-t-e.png" class="certificate-class" (click)="open_url('https://api-courses.cybrary.it/api/certificate/C-5a3cf011f-117bb4/view')" alt="cert">
      		</div>
      		<div class="col-md-6">
      			<img src="assets/certificates/cloudplus.png" class="certificate-class" (click)="open_url('https://api-courses.cybrary.it/api/certificate/C-5a3cf011f-042cab/view')" alt="cert">
      		</div>
      		<div class="col-md-6">

      			<img src="assets/certificates/networkplus.png" class="certificate-class" (click)="open_url('https://api-courses.cybrary.it/api/certificate/C-5a3cf011f-8a7bac/view')" alt="cert">
      		</div>
      		<div class="col-md-6">
      			<img src="assets/certificates/cross-site-script.png" class="certificate-class" (click)="open_url('https://api-courses.cybrary.it/api/certificate/CC-eb9cbe4e-bedc-4a92-b078-f05a999c51f6/view')" alt="cert">
      		</div>
      		<div class="col-md-6">
      			<img src="assets/certificates/mobile-security.png" class="certificate-class" (click)="open_url('https://api-courses.cybrary.it/api/certificate/CC-f761e06c-4242-44b6-a7c8-c04b85796464/view')" alt="cert">
      		</div>
      	</div>
      	<h2 class="w3-text-grey"><i class="fa fa-certificate fa-fw w3-margin-right w3-xxlarge w3-text-teal"></i>Other Certificates  <i class="fa fa-hand-o-down fa-fw w3-text-teal"></i></h2>

      </div>

    <!-- End Right Column -->
    </div>
    
  <!-- End Grid -->
  </div>
  
  <div class="scrolling-wrapper" style="margin-top: 20px;">
  <div class="card">
  	<img src="assets/certificates/python-b-t-a.png" class="certificate-class-with-margin" (click)="open_url('https://www.udemy.com/certificate/UC-NN3QFYJ1/')" alt="cert">
  </div>
  <div class="card">
  	<img src="assets/certificates/p-t-e-h.png" class="certificate-class-with-margin" (click)="open_url('https://api-courses.cybrary.it/api/certificate/C-5a3cf011f-a2f5b1/view')" alt="cert">
  </div>
  <div class="card">
  	<img src="assets/certificates/ccna.png" class="certificate-class-with-margin" (click)="open_url('https://api-courses.cybrary.it/api/certificate/C-5a3cf011f-8c7f89/view')" alt="cert">
  </div>
  <div class="card">
  	<img src="assets/certificates/linux-cert.png" class="certificate-class-with-margin" (click)="open_url('https://www.udemy.com/certificate/UC-KZ1WEO09')" alt="cert">
  </div>
  <div class="card">
  	<img src="assets/certificates/securityplus.png" class="certificate-class-with-margin" (click)="open_url('https://api-courses.cybrary.it/api/certificate/C-5a3cf011f-f861b0/view')" alt="cert"> 
  </div>
  <div class="card" > 
  	<img src="assets/certificates/tcp-ip.png" class="certificate-class-with-margin" (click)="open_url('https://api-courses.cybrary.it/api/certificate/SC-5a3cf011f-a4231/view')" alt="cert">  
  </div>
  <div class="card"> 
  	<img src="assets/certificates/angular-b-t-a.png" class="certificate-class-with-margin" (click)="open_url('https://www.udemy.com/certificate/UC-GY4P3GOD/')" alt="cert">  
  </div>
  <div class="card">

  	<img src="assets/certificates/f-l-admin.png" class="certificate-class-with-margin" (click)="open_url('https://api-courses.cybrary.it/api/certificate/SC-5a3cf011f-31c7f/view')" alt="cert">
  </div>
</div>

<div class="scrolling-wrapper" style="margin-top: 20px;">


  <div class="card"> 
  	<img src="assets/certificates/msi-cert.png" class="certificate-class-with-margin" (click)="open_url('https://ramkrishna.bithost.in/assets/techgig/Certificate_msi.pdf')" alt="cert">
  </div>

  <div class="card"> 
  	<img src="assets/certificates/python-eth.png" class="certificate-class-with-margin" (click)="open_url('https://www.udemy.com/certificate/UC-P9YX25M4')" alt="cert">
  </div>

  <div class="card"> 
  	<img src="assets/certificates/ionic-firebase.png" class="certificate-class-with-margin" (click)="open_url('https://www.udemy.com/certificate/UC-5280dd2a-b6bb-472d-aa93-6be5a769e8a3')" alt="cert">
  </div>

  <div class="card"> 
  	<img src="assets/certificates/dev-ops-b.png" class="certificate-class-with-margin" (click)="open_url('https://www.udemy.com/certificate/UC-aa5e596b-bb8a-4f51-bb62-ee6b0416ce20')" alt="cert">
  </div>

  <div class="card"> 
  	<img src="assets/certificates/php-master.png" class="certificate-class-with-margin" (click)="open_url('https://www.udemy.com/certificate/UC-G7RZZE3Y')" alt="cert">
  </div>
  <div class="card"> 
  	<img src="assets/certificates/php-7-cert.png" class="certificate-class-with-margin" (click)="open_url('https://www.eduonix.com/certificate/a14f01cd46')" alt="cert">
  </div>
	
</div>

<!-- <div class="scrolling-wrapper" style="margin-top: 20px;">

  <div class="card"> 
  	<img src="assets/techgig/tech-c.png" class="certificate-class-with-margin tech-card" alt="tech">
  </div>
  <div class="card"> 
  	<img src="assets/techgig/tech-cplusplus.png" class="certificate-class-with-margin tech-card" alt="tech">
  </div>
  <div class="card"> 
  	<img src="assets/techgig/tech-asp.png" class="certificate-class-with-margin tech-card" alt="tech">
  </div>
  <div class="card"> 
  	<img src="assets/techgig/tech-java.png" class="certificate-class-with-margin tech-card" alt="tech">
  </div>
  <div class="card"> 
  	<img src="assets/techgig/tech-network.png" class="certificate-class-with-margin tech-card" alt="tech">
  </div>
  <div class="card"> 
  	<img src="assets/techgig/tech-html.png" class="certificate-class-with-margin tech-card" alt="tech">
  </div>
	
</div> -->
	<div style="margin-top: 20px;background-color: lightgray; padding: 10px;">
		<pdf-viewer src="assets/techgig/Certificate.pdf" 
              [render-text]="true"
              style="display: block; width: 100%; height: 400px;"></pdf-viewer>
		<!-- <iframe src="assets/techgig/Certificate.pdf" width="100%" height="400px"></iframe> -->
	</div>
  <!-- End Page Container -->
</div>
</div> 
