<footer class="w3-container w3-teal w3-center w3-margin-top">
  <div> 
	  <p>Find me on social media.</p>
	  <a  href="https://www.facebook.com/ramkrishnak2"><i class="fa fa-facebook-official w3-hover-opacity"></i></a>
	  <a class="rk-spacing-in-social-iocon" href="https://www.instagram.com/ramkrishna70/"> <i class="fa fa-instagram w3-hover-opacity"></i></a>  
	  <a class="rk-spacing-in-social-iocon" href="https://twitter.com/ramkrishnadj"><i class="fa fa-twitter w3-hover-opacity"></i></a>
	  <a class="rk-spacing-in-social-iocon" href="https://www.linkedin.com/in/ramk70/"><i class="fa fa-linkedin w3-hover-opacity"></i></a>

	  <a class="rk-spacing-in-social-iocon" href="https://github.com/ramkrishna70/bithost-ramkrishna"><i class="fa fa-github w3-hover-opacity"></i></a>
	  <a class="rk-spacing-in-social-iocon" href="https://pinterest.com/ramkrishna70"><i class="fa fa-pinterest w3-hover-opacity"></i></a>
	  <a class="rk-spacing-in-social-iocon" href="https://stackoverflow.com/story/ramkrishna70"><i class="fa fa-stack-overflow w3-hover-opacity"></i></a>
  </div>
  <div style="margin-top: 10px;"> 
  	<div style="float: left; display: inline;">Powered by <a href="https://www.bithost.in/" target="_blank"><img style="height: 22px;" src="https://bithost.in/assets/fabicon.png"/><span>itHostIn</span></a></div>
  	<div style="float: center; display: inline;"><a href="https://www.bithost.in/">Read My Blogs Here</a></div>
 	<div style="float: right; display: inline;">Copyright &copy; <a style="text-decoration: none;" href="https://ramkrishna.bithost.in" target="_blank">Ram Krishna</a></div>
  </div>
  <div style="float: right;  margin-bottom: 50px; ">Hosted On: <a style="text-decoration: none;" href="https://github.com/ramkrishna70/bithost-ramkrishna" target="_blank">GitHub❤️</a></div>
</footer>